import React, { useEffect, useState } from 'react'
import { Link } from 'gatsby'
import { useLocation } from '@reach/router'
import { Form, Input, Button, Modal } from 'antd'
import { MailOutlined, LockOutlined } from '@ant-design/icons'
import PageLayout from '../SignUp/SignUpLayout'
import './style.less'
import { httpPost } from '../../services/http'
import { storeAuthToken } from '../../utils/auth-token'
import { useDispatch } from 'react-redux'
import mainApi from '../../redux/services/mainApi'

interface Response {
    access_token: string
    token_type: string
    detail: string
}

const Login = () => {
    let location = useLocation()
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const [redirectURL, setRedirectURL] = useState<string>(
        new URLSearchParams(location.search).get('redirect_url')
    )
    const dispatch = useDispatch()

    useEffect(() => {
        setRedirectURL(new URLSearchParams(location.search).get('redirect_url'))
    }, [location.search])

    const error = (message: string) => {
        Modal.error({
            title: 'Login failed',
            content: message,
        })
    }

    const onFinish = async (values: any) => {
        setLoading(true)
        try {
            const res = await httpPost<Response>('/auth/signin', values)
            storeAuthToken(res.data?.access_token || '')
            let redirectingTo = '/app/'
            if (redirectURL) {
                redirectingTo = redirectURL
            }
            dispatch(mainApi.util.resetApiState())
            if (typeof window !== 'undefined') {
                window.location.href = redirectingTo
            }
        } catch (err) {
            if (err.status === 400) {
                error(err.data?.detail)
            } else {
                error(err.message)
            }
            setLoading(false)
            form.resetFields()
        }
    }

    return (
        <Form onFinish={onFinish}>
            <Form.Item
                hasFeedback
                name="email"
                rules={[
                    { required: true, message: 'Please enter your email!' },
                    { type: 'email', message: 'Not a valid email' },
                ]}
                className="signin-input"
                label=""
            >
                <Input
                    prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                    placeholder="Email"
                    className="signin-input"
                />
            </Form.Item>
            <Form.Item
                hasFeedback
                name="password"
                rules={[{ required: true, message: 'Please enter your Password!' }]}
                className="signin-input"
                label=""
            >
                <Input
                    data-private
                    prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                    type="password"
                    placeholder="Password"
                />
            </Form.Item>
            <Form.Item>
                <p></p>
            </Form.Item>
            <Form.Item>
                <Button
                    className="landing-button"
                    htmlType="submit"
                    type="primary"
                    shape="round"
                    loading={loading}
                >
                    Log in
                </Button>
            </Form.Item>
            <Form.Item>
                <div style={{ flexDirection: 'row' }}>
                    <p>Don't have an account?</p>
                    <Link to="/signup/" replace>
                        <Button
                            className="landing-button"
                            type="primary"
                            shape="round"
                            size="small"
                        >
                            Sign up instead
                        </Button>
                    </Link>
                </div>
            </Form.Item>
            <Form.Item>
                <Link to="/forgot-password/" replace>
                    <Button className="landing-button" type="primary" shape="round" size="small">
                        Forgot password?
                    </Button>
                </Link>
            </Form.Item>
        </Form>
    )
}

const LoginContainer = () => {
    useEffect(() => {
        if (typeof window !== 'undefined') {
            window.scrollTo(0, 0)
        }
    }, [])
    return (
        <PageLayout title="Login">
            <div className="login-background">
                <div className="login-background-inner">
                    <div className="login-container">
                        <div>
                            <Link to="/" replace>
                                <div className="logoImgLarge" />
                            </Link>
                        </div>
                        <Login />
                    </div>
                    <br />
                </div>
            </div>
        </PageLayout>
    )
}

export default LoginContainer
